<script setup>

import Layout from "../../layouts/visitorMain.vue";
import PageHeader from "@/components/page-header";

import { useRoute } from 'vue-router';

import { ref, watch, reactive, onMounted } from 'vue';
import axios from "axios";
import Swal from "sweetalert2";
import { useVuelidate } from '@vuelidate/core';
import Lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/lupuorrc.json";
import sendAnimationData from "@/components/widgets/aahdshkdsl.json";
import { required, email ,helpers, requiredIf, numeric, minLength } from '@vuelidate/validators';
import { SimpleBar } from "simplebar-vue3";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

const route = useRoute();

const successTeklif = ref(null)
const errorTeklifLink = ref({title:"HATA!", status:"danger", code:"B2B-VDE-001", message:"Bu bağlantı kullanılamıyor, lütfen yeni bir bağlantı oluşturunuz."})

const userInfo = reactive({
  "ID": 0,
  "WSCode": "",
  "Name": "",
  "lastName": "",
  "unvan" : "",
  "Mobile": "",
  "Email": "",
  "vergino": "",
  "adresID": null,
  "il": null,
  "ilName": "",
  "ilce": null,
  "ilceName": "",
  "vergidairesi": "",
  "vergidairesiData": null,
  "adres": "",
  "paymentMethod" : null,
  "Adresler" : []
})
const getProducts = ref([])
const TotalPrice = ref(0)
const TotalVAT = ref(0)
const offerLinkID = ref(null)

let tokenData = new FormData();
tokenData.append('token', route.query.t)

axios.post(process.env.VUE_APP_B2B_API_URL+'auth/checkTeklifLink', tokenData, {
  headers: {"jwt" : route.query.t}
}).then((res) => {
  if(res.data.status == false){
    successTeklif.value = false
    errorTeklifLink.value.title = res.data.message
    errorTeklifLink.value.code = res.data.code
    if(res.data.description != undefined){
      errorTeklifLink.value.message = res.data.description
    }
    if(res.data.type != undefined){
      errorTeklifLink.value.status = res.data.type
    }
  } else {
    let userData = res.data.user
    userInfo.ID = userData.ID
    userInfo.WSCode = userData.WSCode
    userInfo.Name = userData.Name
    userInfo.lastName = userData.lastName
    userInfo.Mobile = userData.Mobile
    userInfo.Email = userData.Email
    // userInfo.il = userData.CityCode
    userInfo.Adresler = userData.Address
    // changeIl()
    // userInfo.ilce = userData.TownCode
    getProducts.value = res.data.cart
    offerLinkID.value = res.data.offerLinkID
    successTeklif.value = true
  }
}).catch(function (error) {
  successTeklif.value = false
  errorTeklifLink.value.title = error.response.data.message
  errorTeklifLink.value.code = error.response.data.code
});

const IlData = ref([]);
const IlceData = ref([]);
const TaxOffice = ref([]);
const taxOfficeLoading = ref(false)
const ilceLoading = ref(false)

let getIl = new FormData();
getIl.append('type', 'S')
getIl.append('id', null)

axios.post(process.env.VUE_APP_B2B_API_URL+'auth/getRegions', getIl, {
  headers: {"jwt" : route.query.t}
}).then((res) => {
  IlData.value = res.data.regions
});

const defaultOptions = {
  animationData: animationData,
};
const sendAnimation = {
  animationData: sendAnimationData,
};

const title = "Teklif Al"
const items = [
    {
      text: "Elektromarketim.com",
      href: "/",
    },
    {
      text: "Teklif Al",
      active: true,
    },
  ]

function minusValue(index) {
  if (getProducts.value[index]['quantity'] == 1) {
    Swal.fire({
      title: "Ürün Tekliften Çıkarılsın Mı?",
      html: "Ürünün adetini 0 yapıyorsunuz, tekliften kaldırılsın mı?",
      icon: "info",
      confirmButtonText: "Teklif Çıkar",
      cancelButtonText: "Vazgeç",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        getProducts.value.splice(index, 1)
      }
    });
  }
  if (getProducts.value[index]['quantity'] > 1)
    getProducts.value[index]['quantity'] -= 1
}
function plusValue(index){
  getProducts.value[index]['quantity'] += 1
}
function updateValue(index){
  let value = event.target.value;
  if (event.target.value > 0)
    getProducts.value[index]['quantity'] = parseInt(value)
  else if (event.target.value < 1) {
    event.target.value = getProducts.value[index]['quantity'];
    Swal.fire({
      title: "Ürün Tekliften Çıkarılsın Mı?",
      html: "Ürünün adetini 0 yapıyorsunuz, tekliften kaldırılsın mı?",
      icon: "info",
      confirmButtonText: "Teklif Çıkar",
      cancelButtonText: "Vazgeç",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        getProducts.value.splice(index, 1)
      } else {
        event.target.value = getProducts.value[index]['quantity'];
      }
    });
  }
}

watch(
  getProducts, () => {
    TotalPrice.value = 0
    TotalVAT.value = 0
    getProducts.value.forEach((x)=>{ 
      TotalPrice.value += x.quantity * x.price;
      TotalVAT.value += x.quantity * x.price * x.vat / 100
    })
  },
  { deep: true }
)

watch(
  () => userInfo.il,
  (il) => {
    let IlName = IlData.value.find((obj) => obj['value'] == il ? obj : '')
    userInfo.ilName = IlName != undefined ? IlName['label'] : ''
  }
)

watch(
  () => userInfo.ilce,
  (ilce) => {
    let IlceName = IlceData.value.find((obj) => obj['value'] == ilce ? obj : '')
    if(SelectedAddress.value === null){
      userInfo.ilceName = IlceName != undefined ? IlceName['label'] : ''
    }
  }
)

watch(
  () => userInfo.vergidairesiData,
  (vd) => {
    if(SelectedAddress.value === null){
      userInfo.vergidairesi = vd?.label
    }
  }
)

  const Step = ref(0);
  const lastStep = ref(0);

  const BankaBilgileri = [
  {
    'slug': 'isbank',
    'name': 'İş Bankası',
    'sube': 'Yalova',
    'subeNo': '2500',
    'hesapNo': '869665',
    'iban' : 'TR82 0006 4000 0012 5000 869 665'
  },
  {
    'slug': 'yapikredi',
    'name': 'Yapı Kredi Bankası',
    'sube': 'Yalova',
    'subeNo': '103',
    'hesapNo': '61778190',
    'iban' : 'TR24 0006 7010 0000 0061 7781 90'
  },
  {
    'slug': 'garanti',
    'name': 'Garanti Bankası',
    'sube': 'Yalova',
    'subeNo': '1265',
    'hesapNo': '6200653',
    'iban' : 'TR16 0006 2001 2650 0006 2006 53'
  },
  {
    'slug': 'ziraat',
    'name': 'Ziraat Bankası',
    'sube': 'Yalova',
    'subeNo': '404',
    'hesapNo': '31244852',
    'iban' : 'TR06 0001 0004 0431 2448 5250 01'
  },
  {
    'slug': 'vakifbank',
    'name': 'Vakıfbank',
    'sube': 'Yalova',
    'subeNo': '103',
    'hesapNo': '00782983',
    'iban' : 'TR24 0001 5001 5800 7300 7829 83'
  },
  {
    'slug': 'qnbfinansbank',
    'name': 'QNB Finansbank',
    'sube': 'Yalova',
    'subeNo': '770',
    'hesapNo': '29946579',
    'iban' : 'TR68 0011 1000 0000 0029 9465 79'
  },
  {
    'slug': 'vakifkalitim',
    'name': 'Vakıf Katılım Bankası',
    'sube': 'Yalova',
    'subeNo': '210',
    'hesapNo': '141403-1',
    'iban' : 'TR15 0021 0000 0001 4140 3000 01'
  },
]

const KrediKarti = [
  {
    'slug' : 'bonus',
    'name' : 'Bonus',
    'taksit' : [2,3,6]
  },
  {
    'slug' : 'axess',
    'name' : 'Axess',
    'taksit' : [3,6,9]
  },
  {
    'slug' : 'bankkartcombo',
    'name' : 'Bankkart Combo',
    'taksit' : [2,3,4,5,6]
  },
  {
    'slug' : 'world',
    'name' : 'World',
    'taksit' : [5,6,7]
  },
  {
    'slug' : 'maximum',
    'name' : 'Maximum',
    'taksit' : [2,4,5]
  },
  {
    'slug' : 'qnbfinans',
    'name' : 'QNB Finansbank',
    'taksit' : [3,5]
  }
]

const phoneCheck = (value) => /^0 \(\d{3}\) \d{3} \d{2} \d{2}$/.test(value)
const userValidation = {
  "Name": { 
    required : helpers.withMessage('Bu alanı boş bırakamazsınız', required) 
  },
  "lastName": { 
    required : helpers.withMessage('Bu alanı boş bırakamazsınız', required)
  },
  "Mobile": {
    or : (
      helpers.withMessage("Lütfen geçerli bir telefon numarası giriniz.", phoneCheck),
      helpers.withMessage(
        'Telefon, E-Posta alanlarından en az birini doldurunuz.', 
        requiredIf(function() {
          return userInfo.Email == "";
        })
      )
    )
  },
  "Email": { 
    required : helpers.withMessage('Bu alanı boş bırakamazsınız', required),
    email: helpers.withMessage('Lütfen geçerli bir e-posta adresi giriniz.', email)
  },
  "vergino": {
    and: (
      helpers.withMessage('Sadece rakam olması gerekiyor.', numeric),
      helpers.withMessage('En az 10 karakter içermelidir.', minLength(10))
    )
  }
}

const v$ = useVuelidate(userValidation, userInfo, {$autoDirty: true})
async function userInfoCheck(){
  // v$.$validate()
  const Status = await v$.value.$validate()
  
  if(Status){
    Step.value = 2
    lastStep.value = 2
    window.scrollTo(0,0)
  }
}

let timeout = null

const liveResult = ref([])
const liveSearchLoading = ref(false)

function livesearch(){

  showResult()

  if(timeout){
    clearTimeout(timeout)
  }

  if(searchQuery.value.length > 2){

    timeout = setTimeout(() => {

      liveResult.value = []

      liveSearchLoading.value = true

      let formData = new FormData();
      formData.append('name', searchQuery.value)

      axios.post(process.env.VUE_APP_B2B_API_URL+'product/liveSearch', formData).then((data) => {
        liveResult.value = data.data.data
        liveSearchLoading.value = false
      }).catch((error) => {
        console.log('Bir hata oluştu')
        console.log(error)
      })
    }, 500);
  }
}
const searchQuery = ref("")
const searchBoxRef = ref();

const showResults = ref(false)

function showResult(){
  if(searchQuery.value.length > 2){
    showResults.value = true
  } else {
    showResults.value = false
  }
}
function displayResult(x){
  setTimeout(() => {
    showResults.value = x
  }, 250);
}

function addCartProduct(product){

  let productIndex = getProducts.value.find((obj) => obj['caniasID'] == product.caniasID ? obj : false)

  if( productIndex !== undefined){
    productIndex.quantity += 1
  } else {
    getProducts.value.push(product)
  }
}

function removeProduct(index){
  Swal.fire({
    title: "Ürün Tekliften Çıkarılsın Mı?",
    html: "Ürün tekliften kaldırılsın mı?",
    icon: "info",
    confirmButtonText: "Tekliften Çıkar",
    cancelButtonText: "Vazgeç",
    showCancelButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      getProducts.value.splice(index, 1)
    }
  });
}

async function changeIl(){
  userInfo.ilce = null
  userInfo.vergidairesi = null
  userInfo.vergidairesiData = null
  if(userInfo.il != null){
    IlceData.value = [];
    TaxOffice.value = [];
  } else {
    userInfo.ilce = null;
    IlceData.value = [];
    TaxOffice.value = [];
  }
}
function selectMulti(e, t, a){
  if(a !== "" && a !== undefined){
    let x = e.split('-').pop()
    
    if(t == 's'){ userInfo.il = x; changeIl(); }
    if(t == 'i'){ userInfo.ilce = x }
    if(t == 'v'){ userInfo.vergidairesiData = TaxOffice.value.find((obj) => obj['value'] == x ? obj : '') }
  }
}

const sendLoading = ref(true)
const offerDocNumber = ref("")
const offerSendError = ref("")

function submitTeklif(){
  if(userInfo.paymentMethod != undefined){
    Step.value = 3; 
    lastStep.value = 3

    let sendData = new FormData();
    sendData.append('userData', JSON.stringify(userInfo))
    sendData.append('cartData', JSON.stringify(getProducts.value))
    sendData.append('totalPrice', TotalPrice.value)
    sendData.append('totalVAT', TotalVAT.value)
    sendData.append('offerLinkID', offerLinkID.value)

    axios.post(process.env.VUE_APP_B2B_API_URL+'auth/sendTeklif', sendData, {
      headers: {"jwt" : route.query.t}
    }).then((res) => {
      if(res.data.status){
        offerDocNumber.value = res.data.offerDocNumber
        window.scrollTo(0,0)
      } else {
        offerSendError.value = res.data.message
      }
      sendLoading.value = false
    });
  } else {
    Swal.fire('Lütfen ödeme yöntemi seçiniz.')
  }
}

const SelectedAddress = ref(null)

function changePaymentMethod(method){
  userInfo.paymentMethod = method
}

function changeAddress(index){

  SelectedAddress.value = index

  if(index == null){
    userInfo.adresID = null
    userInfo.vergino = ""
    userInfo.il = null
    userInfo.ilce = null
    userInfo.ilName = ""
    userInfo.ilceName = ""
    userInfo.vergidairesi = ""
    userInfo.vergidairesiData = null
    userInfo.adres = ""
    userInfo.unvan = ""
    IlceData.value = []
  } else {
    let isBusiness = userInfo.Adresler[index].IsCompanyActive

    userInfo.adresID = userInfo.Adresler[index].Id

    userInfo.vergino = (isBusiness == "1") ? userInfo.Adresler[index].TaxNumber : userInfo.Adresler[index].IdentityNumber
    userInfo.unvan = (isBusiness == "1") ? userInfo.Adresler[index].Company : userInfo.Adresler[index].FullName
    userInfo.il = userInfo.Adresler[index].CityCode
    userInfo.ilName = userInfo.Adresler[index].City

    changeIl().then(() => {
      userInfo.ilce = userInfo.Adresler[index].TownCode
      userInfo.ilceName = userInfo.Adresler[index].Town
    });
    userInfo.vergidairesi = (isBusiness == "1") ? userInfo.Adresler[index].TaxOffice : userInfo.Adresler[index].City
    userInfo.vergidairesiData = null
    userInfo.adres = userInfo.Adresler[index].Address
  }
}

const adresCheckBox = ref(null)
const adresSlider = ref(null)
function SliderPosition(position){
  if(position == 'L'){
    adresSlider.value.scrollLeft -= adresCheckBox.value.offsetWidth
  } else if(position == 'R'){
    adresSlider.value.scrollLeft += adresCheckBox.value.offsetWidth
  }
}

const pos = ref({ top: 0, left: 0, x: 0, y: 0});
const Dragging = ref(false)
const lastAddressPos = ref(null)

function mouseDownHandler(e){
  pos.value = {
    left: adresSlider.value.scrollLeft,
    top: adresSlider.value.scrollTop,
    x: e.clientX,
    y: e.clientY
  }
  if(e.touches != undefined){
    pos.value.x = e.touches[0].clientX
    pos.value.y = e.touches[0].clientY
  } 
  Dragging.value = true
}

function mouseUpHandler(e){
  if(Dragging.value){
    let dX, dY;
    if(e.touches != undefined){
      dX = e.touches[0].clientX - pos.value.x
      dY = e.touches[0].clientY - pos.value.y
    } else {
      dX = e.clientX - pos.value.x
      dY = e.clientY - pos.value.y
    }

    let Son = Math.ceil(adresSlider.value.scrollLeft + adresCheckBox.value.offsetWidth)

    let enSon = (Son - adresSlider.value.scrollWidth)

    if(enSon < 0){
      adresSlider.value.scrollLeft = pos.value.left - dX
    } else if(Math.floor(enSon) == 0) {
      adresSlider.value.scrollLeft = - adresCheckBox.value.offsetWidth
      pos.value.left = adresSlider.value.scrollLeft
    }

    adresSlider.value.scrollTop = pos.value.top - dY

    if(lastAddressPos.value == null){
      lastAddressPos.value = adresSlider.value.children[adresSlider.value.children.length - 1].getBoundingClientRect().left + pos.value.x
    }
  }
}

function mouseStop(){
  Dragging.value = false
}

function nextStep(step){
  Step.value = step;
  lastStep.value = step;
  window.scrollTo(0,0);
}

async function getTaxOfficeList(searchQuery) {
  taxOfficeLoading.value = true
  let x = new Promise((resolve) => {
    if(TaxOffice.value.length == 0){
      axios.get(process.env.VUE_APP_B2B_API_URL+'auth/getTaxOffice', {params: {'town': userInfo.il}}).then((res) => {
        TaxOffice.value = res.data
        resolve(TaxOffice.value.filter((i) => { return !searchQuery || i.label.TRToUpper().indexOf(searchQuery.TRToUpper()) !== -1 }))
      })
    } else {
      resolve(TaxOffice.value.filter((i) => { return !searchQuery || i.label.TRToUpper().indexOf(searchQuery.TRToUpper()) !== -1 }))
    }
  })
  taxOfficeLoading.value = false
  return x
}

async function getIlData(searchQuery){
  return new Promise((resolve) => {
    let y = IlData.value.filter((i) => { return !searchQuery || i.label.TRToUpper().indexOf(searchQuery.TRToUpper()) !== -1 })
    resolve(y);
  })
}

async function getIlceData(searchQuery){
  ilceLoading.value = true
  let x = new Promise((resolve) => {
    if(IlceData.value.length == 0){
      let getIl = new FormData();
      console.log('Burası Çalıştı')
      getIl.append('type', 'I')
      getIl.append('id', userInfo.il)
      axios.post(process.env.VUE_APP_B2B_API_URL+'auth/getRegions', getIl, {
        headers: {"jwt" : route.query.t}
      }).then((res) => {
        IlceData.value = res.data.regions
        // return res.data.regions.filter((i) => { return !searchQuery || i.label.TRToUpper().indexOf(searchQuery.TRToUpper()) !== -1 })
        resolve(IlceData.value.filter((i) => { return !searchQuery || i.label.TRToUpper().indexOf(searchQuery.TRToUpper()) !== -1 }))
      })
    } else {
      resolve(IlceData.value.filter((i) => { return !searchQuery || i.label.TRToUpper().indexOf(searchQuery.TRToUpper()) !== -1 }))
    }
  })
  ilceLoading.value = false
  return x
}

onMounted(() => {
  window.addEventListener('mouseup', mouseStop)
})

</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row mb-3 placeholder-glow" v-if="successTeklif==null">
          <div class="col-xl-8">
            <div class="card">
              <div class="card-body checkout-tab">
                <form action="#">
                  <div class="step-arrow-nav mt-n3 mx-n3 mb-3">
                    <ul
                      class="nav nav-pills nav-justified custom-nav"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <button class="nav-link fs-15 p-3" type="button">
                          <span class="placeholder placeholder-lg w-100"></span>
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link fs-15 p-3" type="button">
                          <span class="placeholder placeholder-lg w-100"></span>
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link fs-15 p-3" type="button">
                          <span class="placeholder placeholder-lg w-100"></span>
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link fs-15 p-3" type="button">
                          <span class="placeholder placeholder-lg w-100"></span>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content">
                    <div
                      class="tab-pane fade"
                      :class="[Step == 0 ? 'active show' : '']"
                      id="pills-bill-info"
                      role="tabpanel"
                      aria-labelledby="pills-bill-info-tab"
                    >
                      <div>
                        <div class="d-flex align-items-center mb-2 row">
                          <div class="flex-grow-1 col-md-4">
                            <h5 class="placeholder placeholder-lg w-50 mb-1"></h5>
                            <p class="text-muted mb-4">
                              <span class="placeholder placeholder-lg w-100"></span>
                            </p> 
                          </div>
                          <div class="flex-fill col-md-8">
                              <span class="placeholder placeholder-lg w-100"></span>
                          </div>
                        </div>
                      </div>

                      <div class="mt-4">
                        
                        <div
                          class="card product"
                          v-for="aasf in 3"
                          :key="aasf"
                        >
                          <div class="card-body">
                            <div class="row gy-3">
                              <div class="col-sm-auto">
                                <div class="avatar-lg bg-light rounded p-1">
                                  <span class="placeholder placeholder-lg w-100 h-100"></span>
                                </div>
                              </div>
                              <div class="col-sm">
                                <h5 class="fs-15 text-truncate placeholder placeholder-lg w-100"></h5>
                                <ul class="list-inline text-muted">
                                  <li class="list-inline-item">
                                    <span class="placeholder placeholder-lg me-1" style="width: 50px"></span>
                                    <span class="placeholder placeholder-lg" style="width: 100px"></span>
                                  </li>
                                  <li class="list-inline-item">
                                    <span class="placeholder placeholder-lg me-1" style="width: 50px"></span>
                                    <span class="placeholder placeholder-lg" style="width: 100px"></span>
                                  </li>
                                </ul>

                                <span class="placeholder placeholder-lg" style="width: 125px; height: 35px;"></span>
                              </div>
                              <div class="col-sm-auto">
                                <div class="text-lg-end">
                                  <p class="text-muted mb-1 placeholder placeholder-lg w-100"></p>
                                  <h5 class="fs-15 placeholder placeholder-lg w-100">
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- card body -->
                          <div class="card-footer">
                            <div class="row align-items-center gy-3">
                              <div class="col-sm">
                                <div class="d-flex flex-wrap my-n1">
                                  <div class="placeholder placeholder-lg" style="width: 130px; height: 30px;">
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-auto">
                                <div class="d-flex align-items-center gap-2 text-muted">
                                  <div class="placeholder placeholder-lg" style="width: 55px; height: 20px;"></div>
                                  <h5 class="fs-15 mb-0 placeholder placeholder-lg" style="width: 100px; height: 20px;"></h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- end card footer -->
                        </div>
                      </div>


                        <div class="d-flex align-items-start gap-3 mt-3">
                          <span class="placeholder placeholder-lg right ms-auto" style="width: 175px; height: 40px;"></span>
                        </div>
                      </div>  
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="sticky-side-div">
              <div class="card">
                <div class="card-header border-bottom-dashed">
                  <h5 class="card-title mb-0 placeholder placholder-lg" style="width: 125px;"></h5>
                </div>
                <div class="card-body pt-2">
                  <div class="table-responsive">
                    <table class="table table-borderless mb-0">
                      <tbody>
                        <tr v-for="i in 3" :key="i">
                          <td><span class="placeholder placholder-lg" style="width: 75px;"></span></td>
                          <td class="text-end"><span class="placeholder placholder-lg" style="width: 125px;"></span></td>
                        </tr>

                        <tr class="table-active">
                          <th><span class="placeholder placholder-lg" style="width: 75px;"></span></th>
                          <td class="text-end"><span class="placeholder placholder-lg" style="width: 125px;"></span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- end table-responsive -->
                </div>
              </div>

            </div>
            <!-- end stickey -->
          </div>
        </div>
        <div class="row mb-3" v-if="successTeklif==true">
          <div class="col-xl-8">
            <div class="card">
              <div class="card-body checkout-tab">
                <form action="#">
                  <div class="step-arrow-nav mt-n3 mx-n3 mb-3">
                    <ul
                      class="nav nav-pills nav-justified custom-nav"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link fs-15 p-3"
                          :class="[Step == 0 ? 'active' : 'done']"
                          type="button"
                          @click="(lastStep >= 0 && lastStep < 3) ? Step = 0 : ''"
                        >
                          <i
                            class="
                              ri-shopping-cart-line
                              fs-16
                              p-2
                              bg-soft-primary
                              text-primary
                              rounded-circle
                              align-middle
                              me-2
                            "
                          ></i>
                          <span class="d-none d-md-inline">{{ $t("Ürünler") }}</span>
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link fs-15 p-3"
                          :class="[Step == 1 ? 'active' : '', Step > 1 ? 'done' : ''  ]"
                          type="button"
                          @click="(lastStep >= 1 && lastStep < 3) ? Step = 1 : ''"
                        >
                          <i
                            class="
                              ri-user-line
                              fs-16
                              p-2
                              bg-soft-primary
                              text-primary
                              rounded-circle
                              align-middle
                              me-2
                            "
                          ></i>
                          <span class="d-none  d-md-inline">{{ $t("Bilgiler") }}</span>
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link fs-15 p-3"  
                          :class="[Step == 2 ? 'active' : '', Step > 2 ? 'done' : ''  ]"
                          type="button"
                          @click="(lastStep >= 2 && lastStep < 3) ? Step = 2 : ''"
                        >
                          <i
                            class="
                              ri-bank-card-line
                              fs-16
                              p-2
                              bg-soft-primary
                              text-primary
                              rounded-circle
                              align-middle
                              me-2
                            "
                          ></i>
                          <span class="d-none d-md-inline">{{ $t("Ödeme Bilgisi") }}</span>
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link fs-15 p-3"
                          :class="[Step >= 3 ? 'done' : '']"
                          type="button"
                        >
                          <i
                            class="
                              ri-checkbox-circle-line
                              fs-16
                              p-2
                              bg-soft-primary
                              text-primary
                              rounded-circle
                              align-middle
                              me-2
                            "
                          ></i>
                          <span class="d-none d-md-inline">{{ $t("Tamamlandı") }}</span>
                        </button>
                      </li>
                    </ul>
                  </div>

                  <div class="tab-content">
                    <div
                      class="tab-pane fade"
                      :class="[Step == 0 ? 'active show' : '']"
                      id="pills-bill-info"
                      role="tabpanel"
                      aria-labelledby="pills-bill-info-tab"
                    >
                      <div>
                        <div class="d-flex align-items-center mb-2 row">
                          <div class="flex-grow-1 col-md-4">
                            <h5 class="mb-1">{{ $t("Teklif Listesi") }}</h5>
                            <p class="text-muted mb-4">
                              {{ $t("Teklifinize eklediğiniz ürünler") }}
                            </p> 
                          </div>
                          <div class="flex-fill col-md-8">
                            <div class="app-search">
                              <div class="position-relative">
                                <input type="text" class="form-control" placeholder="Ürün Ara..." autocomplete="off" id="search-options"
                                  ref="searchBoxRef"
                                  @keyup="livesearch" @keypress.enter="livesearch"
                                  v-model="searchQuery" @focus="showResult" @focusout="displayResult(false)" />
                                <span class="mdi mdi-magnify search-widget-icon"></span>
                                <span class="
                                    mdi mdi-close-circle
                                    search-widget-icon search-widget-icon-close
                                  "
                                  :class="{'d-none':!showResults}"
                                  ></span>
                              </div>
                              <div class="dropdown-menu dropdown-menu-lg" :class="{'show':showResults}" :style="{width : searchBoxRef!=null?searchBoxRef.clientWidth+'px':300+'px'}">
                                <SimpleBar data-simplebar style="max-height: 320px;">

                                  <!-- item-->
                                  <div class="dropdown-header mt-2">
                                    <h6 class="text-overflow text-muted mb-2 text-uppercase">
                                      Ürünler
                                    </h6>
                                  </div>

                                  <div class="notification-list" v-if="liveResult.length > 0">
                                    <button type="button" class="d-flex dropdown-item notify-item py-2 liveProducts" v-for="product in liveResult" :key="product" @click="addCartProduct(product)">
                                      <img :src="'https://elektromarketim.com/'+product.proIMG" class="me-3 rounded-circle avatar-xs"
                                        alt="user-pic" />
                                      <div class="flex-1">
                                        <h6 class="m-0">{{ product.proName }}</h6>
                                        <span class="fs-11 mb-0 text-muted">
                                          Ürün Kodu : {{ product.proCode }} | Marka : {{ product.proBrand }} | <b>Fiyat : {{ $filters.currency(product.price) }}</b>
                                        </span>
                                      </div>
                                    </button>

                                  </div>
                                  <div class="notification-list placeholder-glow" v-else>
                                    
                                    <a href="javascript:void(0);" class="d-flex dropdown-item notify-item py-2 liveProducts" v-for="i in 3" :key="i">
                                      <div class="flex-shrink-0" style="width: 50px; height: 50px;">
                                        <span class="placeholder w-100 h-100"></span>
                                      </div>
                                      <div class="flex-grow-1 ms-3">
                                        <h6 class="m-0"><span class="placeholder col-12 w-100"></span></h6>
                                        <span class="fs-11 mb-0 text-muted"><span class="placeholder col-12 w-75"></span></span>
                                      </div>
                                    </a>

                                  </div>
                                </SimpleBar>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="mt-4">
                        
                        <div
                          class="card product"
                          v-for="(product, index) in getProducts"
                          :key="product.caniasID"
                        >
                          <div class="card-body">
                            <div @click="removeProduct(index)" style="width: 30px;height: 30px;position: absolute;right: 0;top: 0;background: #e43f3f;border-radius: 0 0 0 30px; cursor: pointer; z-index: 1;">
                              <a type="button" class="text-body float-end">
                                <i class="ri-delete-bin-fill text-muted align-bottom me-1" style="font-size: 17px; color: white !important;"></i>
                              </a>
                            </div>
                            <div class="row gy-3">
                              <div class="col-sm-auto col-5">
                                <div class="avatar-lg bg-light rounded p-1">
                                  <!-- <img :src="'https://www.elektromarketim.com/Data/K/'+product.ImageUrl" alt="" class="img-fluid d-block" /> -->
                                  <img :src="'https://www.elektromarketim.com/'+product.proIMG" alt="" class="img-fluid d-block" />
                                </div>
                              </div>
                              <div class="col-sm col-7">
                                <h5 class="fs-15 text-truncate">
                                  <a :href="(product.seoLink != '') ? 'https://www.elektromarketim.com/'+product.seoLink : 'javascript:;'" :target="(product.seoLink != '') ? '_BLANK' : ''" class="text-dark product-name">
                                    {{ product.proName }}</a
                                  >
                                </h5>
                                <ul class="list-inline text-muted">
                                  <li class="list-inline-item">
                                    {{ $t("Ürün Kodu") }} :
                                    <span class="fw-medium">{{ product.proCode }}</span>
                                  </li>
                                  <li class="list-inline-item">
                                    {{ $t("Marka") }} : <span class="fw-medium">{{ product.proBrand }}</span>
                                  </li>
                                </ul>
                              </div>
                              <div class="col-sm-auto row m-1 p-1 me-3">
                                <div class="col-6 col-md-12">
                                  <div class="text-lg-end text-center">
                                    <p class="text-muted mb-1">{{ $t("Site Fiyatı") }}:</p>
                                    <h5 class="fs-15">
                                      <span id="ticket_price" class="product-price">{{
                                        $filters.currency(product.price)
                                      }}</span>
                                    </h5>
                                  </div>
                                </div>
                                <div class="col-6 col-md-12 text-center text-lg-end">
                                  <div class="input-step">
                                    <button
                                      type="button"
                                      class="minus"
                                      @click="minusValue(index)"
                                    >
                                      –
                                    </button>
                                    <input
                                      type="number"
                                      class="product-quantity"
                                      :value="product.quantity"
                                      @input="updateValue(index)"
                                      min="0"
                                      max="100"
                                    />
                                    <button type="button" class="plus" @click="plusValue(index)">
                                      +
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- card body -->
                          <div class="card-footer">
                            <div class="row align-items-center gy-3">
                              <div class="col-sm-auto">
                                <div class="d-flex align-items-center gap-2 text-muted float-end">
                                  <div>{{ $t("Toplam") }} :</div>
                                  <h5 class="fs-15 mb-0">
                                    <span class="product-line-price">{{
                                      $filters.currency(product.quantity * product.price)
                                    }}</span>
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- end card footer -->
                        </div>
                      </div>


                        <div class="d-flex align-items-start gap-3 mt-3">
                          <button
                            type="button"
                            class="btn btn-primary btn-label right ms-auto nexttab"
                            data-nexttab="pills-bill-address-tab"
                            @click="nextStep(1)"
                          >
                            <i
                              class="
                                ri-user-line
                                label-icon
                                align-middle
                                fs-16
                                ms-2
                              "
                            ></i
                            >{{ $t("Bilgilere Devam Et") }}
                          </button>
                        </div>
                      </div>  
                    <!-- end tab pane -->

                    <div
                      class="tab-pane fade"
                      :class="[Step == 1 ? 'active show' : '']"
                      id="pills-bill-address"
                      role="tabpanel"
                      aria-labelledby="pills-bill-address-tab"
                    >
                      <div>
                        <h5 class="mb-1">{{ $t("Bilgiler") }}</h5>
                        <p class="text-muted mb-3">
                          {{ $t("Lütfen aşağıdaki tüm bilgileri doldurun") }}
                        </p>
                      </div>

                      <div class="row gy-3">
                        <div class="col-xxl-6 col-md-12">
                            <label for="iconInput" class="form-label">Ad <code>*</code></label>
                            <div class="form-icon">
                                <input type="text" class="form-control form-control-icon" id="iconInput"
                                  :class="{'is-invalid' : v$.Name.$errors.length, 'is-valid' : !v$.Name.$invalid}"
                                    placeholder="Ad" v-model="userInfo.Name" required>
                                <i class="ri-user-2-line"></i>
                                <div class="invalid-feedback" v-for="error of v$.Name.$errors" :key="error.$uid">
                                  {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-6 col-md-12">
                            <label for="iconInput" class="form-label">Soyad <code>*</code></label>
                            <div class="form-icon">
                                <input type="text" class="form-control form-control-icon" id="iconInput"
                                    :class="{'is-invalid' : v$.lastName.$errors.length, 'is-valid' : !v$.lastName.$invalid}"
                                    placeholder="Soyad" v-model="userInfo.lastName">
                                <i class="ri-user-2-line"></i>
                                <div class="invalid-feedback" v-for="error of v$.lastName.$errors" :key="error.$uid">
                                  {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-6 col-md-12">
                            <label for="iconInput" class="form-label">Telefon <code v-if="userInfo.Email == '' || userInfo.Mobile != ''">*</code></label>
                            <div class="form-icon">
                                <input type="phone" class="form-control form-control-icon" id="iconInput"
                                    placeholder="+90 (000) 000 00 00" v-maska="['+90 (###) ### ## ##', '+90 (###) ### ## ##']"
                                    :class="{'is-invalid' : v$.Mobile.$errors.length, 'is-valid' : !v$.Mobile.$invalid}" v-model="userInfo.Mobile">
                                <i class="ri-phone-line"></i>
                                <div class="invalid-feedback" v-for="error of v$.Mobile.$errors" :key="error.$uid">
                                  {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-6 col-md-12">
                            <label for="iconInput" class="form-label">E-Posta <code v-if="userInfo.Mobile == '' || userInfo.Email != ''">*</code></label>
                            <div class="form-icon">
                                <input type="phone" class="form-control form-control-icon" id="iconInput"
                                    placeholder="info@elektromarketim.com" v-model="userInfo.Email"
                                    :class="{'is-invalid' : v$.Email.$errors.length, 'is-valid' : !v$.Email.$invalid}">
                                <i class="ri-mail-line"></i>
                                <div class="invalid-feedback" v-for="error of v$.Email.$errors" :key="error.$uid">
                                  {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                          <div class="row">
                            <div class="col-12">
                              <div class="flex-grow-1 mb-2">
                                <h5 class="fs-15 mb-0">{{ $t("Kayıtlı Fatura Bilgileri") }}</h5>
                              </div>
                            </div>
                            <div class="col-12">
                              <b-button pill variant="outline-success" class="btn-icon waves-effect waves-light adresLeft" @click="SliderPosition('L')"><i class="bx bx-left-arrow-circle"></i></b-button>
                              <b-button pill variant="outline-success" class="btn-icon waves-effect waves-light adresRight" @click="SliderPosition('R')"><i class="bx bx-right-arrow-circle"></i></b-button>
                              <div class="row adreslerSlide col-11" ref="adresSlider" @mousedown="mouseDownHandler" @mousemove="mouseUpHandler" @touchstart="mouseDownHandler" @touchmove="mouseUpHandler" @touchend="mouseStop">
                                <div class="col-lg-4 col-sm-6" ref="adresCheckBox">
                                  <div class="form-check card-radio">
                                    <input
                                      id="faturaAddressNew"
                                      name="faturaAddress"
                                      type="radio"
                                      class="form-check-input"
                                      @input="changeAddress(null)"
                                      checked
                                    />
                                    <label
                                      class="form-check-label"
                                      for="faturaAddressNew"
                                      style="height: 200px;"
                                    >
                                      <span
                                        class="
                                          mb-4
                                          fw-bold
                                          d-block
                                          text-muted text-uppercase
                                        "
                                        >Yeni Adres Ekle</span
                                      >

                                      <span class="fs-15 mb-2">{{ SelectedAddress == null ? (userInfo.unvan != "" ? userInfo.unvan : userInfo.Name + " " + userInfo.lastName) : '' }}</span>
                                      <span
                                        class="
                                          text-muted
                                          fw-normal
                                          text-wrap
                                          mb-1
                                          d-block
                                        "
                                        >{{ SelectedAddress == null ? userInfo.adres : '' }} {{ SelectedAddress == null ? userInfo.ilName : '' }} / {{ SelectedAddress == null ? userInfo.ilceName : '' }} 
                                      </span>
                                      <span class="text-muted fw-normal d-block"
                                        >VD: {{ SelectedAddress == null ? userInfo.vergidairesi : '' }} </span
                                      >
                                      <span class="text-muted fw-normal d-block"
                                        >VN: {{ SelectedAddress == null ? userInfo.vergino : '' }}  </span
                                      >
                                    </label>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-sm-6" v-for="(adres, index) in userInfo.Adresler" :key="adres.Id">
                                  <div class="form-check card-radio">
                                    <input
                                      :id="'faturaAddress'+adres.Id"
                                      name="faturaAddress"
                                      type="radio"
                                      class="form-check-input"
                                      @input="changeAddress(index)"
                                    />
                                    <label
                                      class="form-check-label"
                                      :for="'faturaAddress'+adres.Id"
                                      style="height: 200px;"
                                    >
                                      <span
                                        class="
                                          mb-4
                                          fw-bold
                                          d-block
                                          text-muted text-uppercase
                                        "
                                        >{{ adres.Title }}</span
                                      >

                                      <span class="fs-15 mb-2">{{ adres.Company != "" ? adres.Company : adres.FullName }}</span>
                                      <span
                                        class="
                                          text-muted
                                          fw-normal
                                          text-wrap
                                          mb-1
                                          d-block
                                        "
                                        >{{ adres.Address }} {{ adres.Town }} / {{ adres.City }} 
                                      </span>
                                      <span class="text-muted fw-normal d-block"
                                        >VD: {{ adres.TaxOffice != "" ? adres.TaxOffice : adres.City }}</span
                                      >
                                      <span class="text-muted fw-normal d-block"
                                        >VN: {{ adres.TaxNumber != "" ? adres.TaxNumber : adres.IdentityNumber }}  </span
                                      >
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xxl-12 col-md-12">
                          <label for="iconInput" class="form-label">Ünvan</label>
                            <div class="form-icon">
                                <input type="text" class="form-control form-control-icon" id="iconInput"
                                    :disabled="SelectedAddress != null"
                                    placeholder="Ünvan" v-model="userInfo.unvan"
                                    >
                                <i class="ri-mail-line"></i>
                            </div>
                        </div>
                        <div class="col-xxl-6 col-md-12">
                            <label for="iconInput" class="form-label">İl</label>
                            <Multiselect
                              class="form-control"
                              v-model="userInfo.il"
                              placeholder="İl"
                              @keydown.tab="selectMulti($event.target.attributes['aria-activedescendant']?.value, 's', $event.srcElement.attributes['modelValue']?.value)"
                              :close-on-select="true"
                              :searchable="true"
                              :canClear="false"
                              :canDeselect="false"
                              :filter-results="false"
                              :resolve-on-load="true"
                              :min-chars="0"
                              :delay="0"
                              :options="getIlData"
                              @select="changeIl"
                              :disabled="SelectedAddress != null"
                            />
                        </div>
                        <div class="col-xxl-6 col-md-12">
                            <label for="iconInput" class="form-label">İlçe</label>
                            <Multiselect
                              :key="userInfo.il"
                              class="form-control"
                              v-model="userInfo.ilce"
                              :placeholder="userInfo.il === null ? 'İl seçiniz' : 'İlçe'"
                              @keydown.tab="selectMulti($event.target.attributes['aria-activedescendant']?.value, 'i', $event.srcElement.attributes['modelValue']?.value)"
                              :close-on-select="true"
                              :searchable="true"
                              :canClear="false"
                              :canDeselect="false"
                              :filter-results="false"
                              :resolve-on-load="true"
                              :min-chars="0"
                              :delay="0"
                              :options="userInfo.il !== null ? getIlceData : null"
                              :disabled="SelectedAddress != null || userInfo.il === null"
                            />
                        </div>
                        <div class="col-xxl-6 col-md-12">
                            <label for="iconInput" class="form-label">T.C. Kimlik No / Vergi Numarası</label>
                            <div class="form-icon">
                                <input type="text" class="form-control form-control-icon" id="iconInput"
                                    :maxlength="11" :minlength="10" :disabled="SelectedAddress != null"
                                    placeholder="T.C. Kimlik No / Vergi Numarası" v-model="userInfo.vergino"
                                    :class="{'is-invalid' : v$.vergino.$errors.length, 'is-valid' : (!v$.vergino.$invalid && userInfo.vergino.length > 9)}">
                                <i class="ri-mail-line"></i>
                                <div class="invalid-feedback" v-for="error of v$.vergino.$errors" :key="error.$uid">
                                  {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-6 col-md-12">
                            <label for="iconInput" class="form-label">Vergi Dairesi</label>
                            <div class="form-icon">
                                <Multiselect
                                  :key="userInfo.il"
                                  :object="true"
                                  class="form-control"
                                  v-model="userInfo.vergidairesiData"
                                  :placeholder="userInfo.il === null ? 'İl seçiniz' : SelectedAddress !== null ? userInfo.vergidairesi : 'Vergi Dairesi'"
                                  @keydown.tab="selectMulti($event.target.attributes['aria-activedescendant']?.value, 'v', $event.srcElement.attributes['modelValue']?.value)"
                                  :close-on-select="true"
                                  :searchable="true"
                                  :canClear="false"
                                  :canDeselect="false"
                                  :filter-results="false"
                                  :resolve-on-load="true"
                                  :preserveSearch="false"
                                  :loading="ilceLoading"
                                  :min-chars="0"
                                  :delay="0"
                                  :noOptionsText="userInfo.il !== null ? 'Lütfen öncelikle il seçiniz...' : 'Vergi dairesi bulunamadı.'"
                                  :options="userInfo.il !== null ? getTaxOfficeList : null"
                                  :disabled="SelectedAddress != null || userInfo.il === null"
                                />
                            </div>
                        </div>
                        <div class="col-xxl-12 col-md-12">
                            <label for="iconInput" class="form-label">Adres</label>
                            <div class="form-icon">
                                <textarea type="text" class="form-control form-control-icon" id="iconInput"
                                    :disabled="SelectedAddress != null"
                                    placeholder="Çiftlik Mahallesi, Küçük Sanayi Sitesi G Blok No:24/13-16, 77600 Çiftlikköy/Yalova" v-model="userInfo.adres"></textarea>
                                <i class="ri-mail-line"></i>
                            </div>
                        </div>
                      </div>

                      <div class="d-flex align-items-start gap-3 mt-4">
                        <button
                          type="button"
                          class="btn btn-light btn-label previestab"
                          data-previous="pills-bill-info-tab"
                          @click="Step = 0"
                        >
                          <i
                            class="
                              ri-arrow-left-line
                              label-icon
                              align-middle
                              fs-16
                              me-2
                            "
                          ></i
                          >{{ $t("Ürünlere Geri Dön") }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary btn-label right ms-auto nexttab"
                          data-nexttab="pills-payment-tab"
                          @click="userInfoCheck()"
                        >
                          <i
                            class="
                              ri-bank-card-line
                              label-icon
                              align-middle
                              fs-16
                              ms-2
                            "
                          ></i
                          >{{ $t("Ödeme Bilgisine Et") }}
                        </button>
                      </div>
                    </div>
                    <!-- end tab pane -->

                    <div
                      class="tab-pane fade"
                      :class="[Step == 2 ? 'active show' : '']"
                      id="pills-payment"
                      role="tabpanel"
                      aria-labelledby="pills-payment-tab"
                    >
                      <div>
                        <h5 class="mb-1">{{ $t("Ödeme Bilgisi") }}</h5>
                        <p class="text-muted mb-4">
                          {{ $t("Lütfen ödeme yöntemi seçin.") }}
                        </p>
                      </div>

                      <div class="row g-4">
                        <div class="col-lg-6 col-sm-6">
                          <div
                            data-bs-toggle="collapse"
                            data-bs-target="#kkmethodCollapse"
                            aria-expanded="false"
                            aria-controls="paymentAccordion">
                            <div class="form-check card-radio">
                              <input
                                id="paymentMethod01"
                                name="paymentMethod"
                                type="radio"
                                class="form-check-input"
                                @input="changePaymentMethod('KK')"
                              />
                              <label class="form-check-label" for="paymentMethod01">
                                <span class="fs-16 text-muted me-2"
                                  ><i class="ri-bank-card-line align-bottom"></i
                                ></span>
                                <span class="fs-14 text-wrap">{{ $t("Banka/Kredi Kartı") }}</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div
                            data-bs-toggle="collapse"
                            data-bs-target="#paymentmethodCollapse"
                            aria-expanded="false"
                            aria-controls="paymentAccordion">
                            <div class="form-check card-radio">
                              <input
                                id="paymentMethod02"
                                name="paymentMethod"
                                type="radio"
                                class="form-check-input"
                                @input="changePaymentMethod('Havale')"
                              />
                              <label class="form-check-label" for="paymentMethod02">
                                <span class="fs-16 text-muted me-2"
                                  ><i class="ri-arrow-left-right-line align-bottom"></i
                                ></span>
                                <span class="fs-14 text-wrap">{{ $t("Havale/EFT") }}</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion d-none d-md-block" id="paymentAccordion">
                      <div class="accordion-collapse collapse" id="paymentmethodCollapse" data-bs-parent="#paymentAccordion">
                        <div class="card p-4 border shadow-none mb-0 mt-4">
                          <div class="table-responsive">
                            <table class="table align-middle mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">{{ $t("Banka") }}</th>
                                        <th scope="col">{{ $t("Banka Bilgileri") }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="banka in BankaBilgileri" :key="banka.slug">
                                        <td>
                                            <img :src="require('@/assets/images/banka/' + banka.slug + '.png')" alt="" style="width: 100px;" />
                                        </td>
                                        <td>
                                            {{ banka.name }}
                                        </td>
                                        <td>{{ banka.sube }} / {{ banka.subeNo}} - {{ banka.hesapNo}}<br /><code>{{ banka.iban }}</code></td>
                                    </tr>
                                </tbody>  
                            </table>
                            <!-- end table -->
                        </div>
                        <!-- end table responsive -->
                        </div>
                      </div>

                      
                      <div class="accordion-collapse collapse" id="kkmethodCollapse" data-bs-parent="#paymentAccordion">
                        <div class="card p-4 border shadow-none mb-0 mt-4">
                          <div class="table-responsive">
                            <table class="table align-middle mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <th scope="col" style="width: 10%">{{ $t("Taksit Sayısı") }}</th>
                                        <th scope="col" v-for="kk in KrediKarti" :key="kk.slug">
                                            <img :src="require('@/assets/images/banka/' + kk.slug + '.png')" alt="" style="width: 100px;" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="taksitSayisi in [2,3,4,5,6,7,8,9]" :key="taksitSayisi">
                                        <td>
                                          {{taksitSayisi}}
                                        </td>
                                        <td class="text-center" v-for="kk in KrediKarti" :key="kk.slug">
                                          <i :class="(kk.taksit.indexOf(taksitSayisi) !=  -1) ? ['ri-checkbox-circle-line', 'text-success', 'fw-bold'] : ['ri-close-circle-line', 'text-danger']"></i>
                                        </td>
                                    </tr>
                                </tbody>  
                            </table>
                            <!-- end table -->
                        </div>
                        <!-- end table responsive -->
                        </div>
                      </div>

                      </div>

                      <div class="d-flex align-items-start gap-3 mt-4">
                        <button
                          type="button"
                          class="btn btn-light btn-label previestab"
                          data-previous="pills-bill-address-tab"
                          @click="Step = 1"
                        >
                          <i
                            class="
                              ri-arrow-left-line
                              label-icon
                              align-middle
                              fs-16
                              me-2
                            "
                          ></i
                          >{{ $t("Bilgilere Geri Dön") }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary btn-label right ms-auto nexttab"
                          data-nexttab="pills-finish-tab"
                          @click="submitTeklif()"
                        >
                          <i
                            class="
                              ri-shopping-basket-line
                              label-icon
                              align-middle
                              fs-16
                              ms-2
                            "
                          ></i
                          >{{ $t("teklif-iste") }}  
                        </button>
                      </div>
                    </div>
                    <!-- end tab pane -->

                    <div
                      class="tab-pane fade"
                      :class="[Step == 3 ? 'active show' : '']"
                      id="pills-finish"
                      role="tabpanel"
                      aria-labelledby="pills-finish-tab"
                    >
                      <div class="text-center py-5">
                      <Transition
                        mode="out-in"
                        enter-active-class="animate__animated animate__zoomIn animate__faster"
                        leave-active-class="animate__animated animate__zoomOut animate__faster"
                        >
                        <div v-if="sendLoading">
                            <div class="mb-4">
                              <lottie
                                colors="primary:#67b173,secondary:#3d78e3"
                                :options="sendAnimation"
                                :height="120"
                                :width="120"
                              />
                            </div>
                            <h5>{{ $t("Teklifiniz gönderiliyor...") }}</h5>
                            <p class="text-muted">
                              {{ $t("Lütfen bekleyiniz...") }}
                            </p>
                        </div>
                        <div v-else>
                            <div class="mb-4">
                              <lottie
                                colors="primary:#67b173,secondary:#3d78e3"
                                :options="defaultOptions"
                                :height="120"
                                :width="120"
                              />
                            </div>
                            <h5>{{ $t("Teşekkürler ! Teklifiniz Tamamlandı!") }}</h5>
                            <p class="text-muted" v-if="offerSendError == ''">
                              {{ $t("Başarıyla teklif oluşturuldu, teklifin işleme alınması için lütfen mail adresinize gelen bağlantıya tıklayarak teklifinizi doğrulayınız.") }}
                            </p>
                            <p class="text-muted" v-else>
                              {{ offerSendError }}
                            </p>

                            <h3 class="fw-semibold" v-if="offerSendError == ''">
                              {{ $t("Teklif Nu.") }}:
                              <span class="text-decoration-underline">
                                {{ offerDocNumber }}
                              </span>
                            </h3>
                        </div>
                      </Transition>
                      </div>
                    </div>
                    <!-- end tab pane -->
                  </div>
                  <!-- end tab content -->
                </form>
              </div>
              <!-- end card body -->
            </div>
          </div>
          <!-- end col -->

          <div class="col-xl-4">
            <div class="sticky-side-div">
              <div class="card">
                <div class="card-header border-bottom-dashed">
                  <h5 class="card-title mb-0">{{ $t("Tahmini Teklif Özeti") }}</h5>
                </div>
                <div class="card-body pt-2">
                  <div class="table-responsive">
                    <table class="table table-borderless mb-0">
                      <tbody>
                        <tr>
                          <td>{{ $t("Ara Toplam") }} :</td>
                          <td class="text-end" id="cart-subtotal">
                            {{ $filters.currency(TotalPrice ) }}
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t("KDV") }} :</td>
                          <td class="text-end" id="cart-subtotal">
                            {{ $filters.currency( TotalVAT ) }}
                          </td>
                        </tr>
                        <tr class="table-active">
                          <th>{{ $t("Toplam") }} :</th>
                          <td class="text-end">
                            <span class="fw-semibold" id="cart-total">
                              {{ $filters.currency( TotalPrice + TotalVAT ) }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- end table-responsive -->
                </div>
              </div>

            </div>
            <!-- end stickey -->
          </div>
        </div>
        <div class="row mb-3" v-if="successTeklif==false">
          <!-- Danger Alert -->
          <b-alert dismissible show :variant="errorTeklifLink.status" class="alert-additional fade show" role="alert">
            <div class="alert-body">
            <div class="d-flex">
            <div class="flex-shrink-0 me-3">
            <i class="ri-error-warning-line fs-16 align-middle"></i>
            </div>
            <div class="flex-grow-1">
            <h5 class="alert-heading">{{errorTeklifLink.title}}</h5>
            <p class="mb-0">{{ errorTeklifLink.message }}</p>
            </div>
            </div>
            </div>
            <div class="alert-content">
            <p class="mb-0">Bir hata olduğunu düşünüyorsanız, bizimle iletişime geçiniz. (Hata : {{ errorTeklifLink.code }})</p>
            </div>
          </b-alert>
        </div>
    </Layout>
</template>
<style scoped>
  .avatar-lg{
    height: 8rem;
    width: 6rem;
  }
  .avatar-lg img{
    height: 100%;
    width: auto;
    margin: 0 auto;
  }  .form-icon i{
    height: 40px;
  }
  .avatar-xs {
    height: 50px;
    width: 50px;
    object-fit: cover;
  }


  .liveProducts{
    word-break: break-word !important;
    white-space: break-spaces !important;
  }
  .app-search{
    padding: 0 !important;
  }
  .adreslerSlide{
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
    display: flex;
    flex-wrap: initial;
    margin: 0 auto;
  }
  .adresRight{
    position: absolute;
    z-index: 1;
    right: 0;
    top: 40%;
  }
  .adresLeft{
    position: absolute;
    z-index: 1;
    left: 0;
    top: 40%;
  }

  .adresRight i, .adresLeft i{
    font-size: 25px;
  }
  .product-name{
    white-space: break-spaces;
  }
  .product .card-header{
    height: 15px;
    border-bottom: none;
    padding: 0;
    margin: 0;
  }
</style>